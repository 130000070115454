@font-face{
    font-family: montserrat;
    src: url('../fonts/montserrat/static/Montserrat-Regular.ttf');
    font-display: swap;  
}

@font-face {
    font-family: montserrat;
    src: url('../fonts/montserrat/static/Montserrat-Bold.ttf');
    font-weight: bold;
    font-display: swap;  

}

@font-face {
    font-family: montserrat;
    src: url('../fonts/montserrat/static/Montserrat-Italic.ttf');
    font-style: italic;
    font-display: swap;  

}

@font-face {
    font-family: montserrat;
    src: url('../fonts/montserrat/static/Montserrat-BoldItalic.ttf');
    font-weight: bold;
    font-style: italic;
    font-display: swap;  

}

@font-face {
    font-family: montserrat;
    src: url('../fonts/montserrat/static/Montserrat-Light.ttf');
    font-weight: 300;
    font-display: swap;  

}

@font-face {
    font-family: montserrat;
    src: url('../fonts/montserrat/static/Montserrat-LightItalic.ttf');
    font-weight: 300;
    font-style: italic;
    font-display: swap;  

}

@font-face {
    font-family: montserrat;
    src: url('../fonts/montserrat/static/Montserrat-Medium.ttf');
    font-weight: 500;
    font-display: swap;  

}

@font-face {
    font-family: montserrat;
    src: url('../fonts/montserrat/static/Montserrat-MediumItalic.ttf');
    font-weight: 500;
    font-style: italic;
    font-display: swap;  

}

@font-face {
    font-family: montserrat;
    src: url('../fonts/montserrat/static/Montserrat-SemiBold.ttf');
    font-weight: 600;
    font-display: swap;  

}

@font-face {
    font-family: montserrat;
    src: url('../fonts/montserrat/static/Montserrat-SemiBoldItalic.ttf');
    font-weight: 600;
    font-style: italic;
    font-display: swap;  

}

@font-face {
    font-family: montserrat;
    src: url('../fonts/montserrat/static/Montserrat-Thin.ttf');
    font-weight: 100;
    font-display: swap;  

}

@font-face {
    font-family: montserrat;
    src: url('../fonts/montserrat/static/Montserrat-ThinItalic.ttf');
    font-weight: 100;
    font-style: italic;
    font-display: swap;  

}

@font-face {
    font-family: montserrat;
    src: url('../fonts/montserrat/static/Montserrat-ExtraBold.ttf');
    font-weight: 800;
    font-display: swap;  

}

@font-face {
    font-family: montserrat;
    src: url('../fonts/montserrat/static/Montserrat-ExtraBoldItalic.ttf');
    font-weight: 800;
    font-style: italic;
    font-display: swap;  

}

@font-face {
    font-family: montserrat;
    src: url('../fonts/montserrat/static/Montserrat-ExtraLight.ttf');
    font-weight: 200;
    font-display: swap;  

}

@font-face {
    font-family: montserrat;
    src: url('../fonts/montserrat/static/Montserrat-ExtraLightItalic.ttf');
    font-weight: 200;
    font-style: italic;
    font-display: swap;  

}

@font-face {
    font-family: montserrat;
    src: url('../fonts/montserrat/static/Montserrat-Black.ttf');
    font-weight: 900;
    font-display: swap;  

}

@font-face {
    font-family: montserrat;
    src: url('../fonts/montserrat/static/Montserrat-BlackItalic.ttf');
    font-weight: 900;
    font-style: italic;
    font-display: swap;  

}

@font-face{
    font-family: poppins;
    src: url('../fonts/poppins/Poppins-Regular.ttf');
    font-display: swap;  
}

@font-face{
    font-family: poppins;
    src: url('../fonts/poppins/Poppins-Bold.ttf');
    font-weight: bold;
    font-display: swap;  
}

@font-face{
    font-family: poppins;
    src: url('../fonts/poppins/Poppins-Medium.ttf');
    font-weight: 500;
    font-display: swap;  
}

@font-face{
    font-family: poppins;
    src: url('../fonts/poppins/Poppins-SemiBold.ttf');
    font-weight: 600;
    font-display: swap;
}

@font-face{
    font-family: poppins;
    src: url('../fonts/poppins/Poppins-Light.ttf');
    font-weight: 300;
    font-display: swap;
}

@font-face{
    font-family: poppins;
    src: url('../fonts/poppins/Poppins-Bold.ttf');
    font-weight: 700;
    font-display: swap;
}


@font-face{
    font-family: poppins;
    src: url('../fonts/poppins/Poppins-Black.ttf');
    font-weight: 900;
    font-display: swap;
}


*{
    margin:0;
    padding: 0;
}
body{
    font-family: montserrat !important;
}

:root{
    --primary-color: #ae0100;
    --primary-color-light: #cd0001;
    --secondary-color: #1a3460; 
    --light-gray: #d8e0e7;
    --success-color: #7fba02;
    --light-gray-2: #f2f2f2;
    /* 80b4c1 */
}
.text-mnt{
    font-family: montserrat !important;

}

.text-poppins{
    font-family: poppins !important;
}
.text-zk-primary{
    color: var(--primary-color);
}
.text-zk-secondary{
    color: var(--secondary-color);
}
.text-zk-primary-light{
    color: var(--primary-color-light);
}
.hover-link-primary:hover{
    color: var(--primary-color-light);
}
/* .text-zk-primary-light:hover{
    color: var(--primary-color);
} */
.bg-zk-primary-light{
    background-color: var(--primary-color-light);
} 
.bg-zk-primary-light:hover{
    background-color: var(--primary-color) !important;
}
.bg-zk-primary{
    background-color: var(--primary-color);
}

.bg-zk-secondary{
    background-color: var(--secondary-color);
}

.bg-zk-success{
    background-color: var(--success-color);
}

.number-bg-rounded{
    /* width: 27px;
    margin-right: 10px;
    line-height: 20px;
    border-radius: 50%;
    text-align: center;
    font-size: 18px;
    background-color: #ffffff;
    color: #000000;
    font-weight: 600;
    padding-top: 4px; */
    font-style: normal;
    font-size: 13px;
    display: inline-block;
    background-color: #fff;
    color: #00247a;
    text-align: center;
    border-radius: 100%;
    width: 25px;
    height: 25px;
    line-height: 25px;
    font-weight: 600;
    position: relative;
    top: -2px;

}

.steps-number-how-work{
    font-size: 7em;
    line-height: initial;
    padding: 0;
    font-weight: 700;
    font-family: Arial, Helvetica, sans-serif;
}
/* arrow to right on header-process-item */

#home-header .logo-img{
    height:57px !important;
}

.logo-img{
    height:58px !important;
}
.banner-video{
    z-index: -10;
    /* position: absolute; */
}

.StripeElement {
    display: block;
    max-width: 100%;
    padding: 15px 14px;
    /* box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px; */
    /* border-radius: 4px; */
    border: 1px solid #c9c9c9;
    background: white;
  }
  
  .StripeElement--focus {
    box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
  }

#myVideo {
    object-fit: cover;
    position: absolute;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
}
#myVideo-mb {
    object-fit: cover;
    position: absolute;
    width: 100%;
    height: 60vh;
    position: absolute;
    z-index: -1;
}
/* .header-home{
    background: url("../../assets/images/home/banner.mp4");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 90vh;

} */
.home-banner-common{
    height: 100vh;
    position: relative;
}
/* .home-banner-mb{
    background: url("../../assets/images/home/banner_mb.webp") no-repeat;
    background-position: contain;
    object-fit: cover;
    position: absolute;
    width: 100%;
    height:100vh;
    position: absolute;
    z-index: -1;

} */
.banner-section{
    position: relative;
    /* height: ; */
}
.home-page-banner-content{
    color: #fff;
    padding: 10px;
    position: absolute;
    text-transform: capitalize;
    left: 10vw;
    top: 9vw;
    width: 60%;
}

.home-page-banner-title{
    font-size: 3vw;
    font-weight: 700;
    line-height: 1.2;
}

.home-page-banner-desc{
    font-size: 1.5vw;
    font-weight: 400;
    line-height: 1.5;
}

.btn-zk-primary-light-home{
    background-color: var(--primary-color-light);
    color: #fff !important;
    border-radius: 30px 0 30px 30px;
    padding: 4px 35px;
    font-size: 18px;
    transition: all 0.3s ease;
}

.btn-zk-primary-light-home:hover{
    background-color: var(--primary-color);
    color: #fff !important;
    border-radius: 30px 30px 30px 0px;
    /* animate */
    transition: all 0.3s ease;
    
}
.btn-zk-primary-light-home:active{
    background-color: var(--primary-color) !important;
    color: #fff !important;
    border-radius: 30px 30px 30px 0px;
    /* animate */
    transition: all 0.3s ease;
    
}
.footer-link-hover:hover{
    color: var(--light-gray) !important;
    text-decoration: none;
}
.page-banner-content{
    color: #fff;
    padding: 10px;
    position: absolute;
    text-transform: capitalize;
    text-align: center;
    top: 9vw;
    width: 100%;
}
.page-banner-title{
    font-size: 3.5vw;
    font-weight: 700;
    line-height: 1.1;
}
.shadow-zk-1{
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
}

.shadow-zk-2{
    box-shadow: 7px 7px 5px #ddd;
}

#website-nav {
    /* background-color: var(--primary-color); */
    /* background-color: #fff; */
    padding: 0rem;
    padding-top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


#website-nav #home-header .nav-link{
    color: #fff;
    text-decoration: none;
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
    font-weight: 600 !important;
    margin-right: 20px;
    font-family: montserrat !important;
}
#website-nav #home-header .navbar-toggler{
    border: 0;
    background-color: #ffffff;
    border-radius: 7px;
    
}

#website-nav .nav-link{
    color: #000;
    text-decoration: none;
    padding: 0.5rem 1rem;
    font-size: 0.85rem;
    font-weight: 600 !important;
    margin-right: 10px;
    font-family: montserrat !important;
}
#website-nav .navbar-toggler{
    border: 0;
    background-color: #ffffff;
    border-radius: 7px;
    
}


#website-nav .navbar-toggler-icon{
    width:1.8rem !important;
    height: 1.8rem !important;
}
.offcanvas.offcanvas-start{
    width: 70% !important;
}
#website-nav .navbar-toggler:focus{
    outline: none;
    box-shadow: none;
}

#website-nav .offcanvas{
    background-color: var(--primary-color);
    color: #fff;
    padding: 0rem;
    /* display: flex; */
    /* justify-content: space-between; */
    /* align-items: center; */
}

.home-section-container{
    padding-top: 100px
}

.bg-zk-home-get-started{
    background: url("../../assets/images/home/get-started-bg.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 20px;
}

.container-zk{
    max-width: 92%;
    padding-right: 4%;
    padding-left: 4%;
    margin-right: auto;
    margin-left: auto;
}

.container-zk2{
    max-width: 86%;
    padding-right: 7%;
    padding-left: 7%;
    margin-right: auto;
    margin-left: auto;
}

.border-shadow-in{
    filter: drop-shadow(0 0 0.75rem rgb(225, 223, 223));
}


#id_17{
    display: none;
}

.fs-zk-48{
    font-size: 48px;
}

.fs-zk-40{
    font-size: 40px;
}
.fs-zk-35{
    font-size: 35px;
}

.fs-zk-32{
    font-size: 32px;
}

.fs-zk-30{
    font-size: 30px;
}

.fs-zk-28{
    font-size: 28px;
}

.fs-zk-24{
    font-size: 24px;
}
.fs-zk-22{
    font-size: 22px;
}
.fs-zk-20{
    font-size: 20px;
}

.fs-zk-18{
    font-size: 18px;
}
.fs-zk-17{
    font-size: 17px;
}

.fs-zk-16{
    font-size: 16px;
}

.fs-zk-15{
    font-size: 15px;
}

.fs-zk-14{
    font-size: 14px;
}

.fs-zk-13{
    font-size: 13px;
}
.fs-zk-12{
    font-size: 12px;
}


/* .about-apply-section {
    background: url("../images/about/applynowbg.png");
    position: relative;
    min-height: 300px;
    width: 100%;
} */

/* .get-started-negative{
    margin-top: -60px;
    z-index: 5;
    position: relative;
} */

.footer-bg{
    background: url("../../assets/images/footer.webp");
}

.text-zk-primary-light-hover{
    color: var(--primary-color-light) !important;
    transition: 0.3s;
}

.footer-link{
    color: #000;
}

.rounded10{
    border-radius: 10px;
}
.rounded20{
    border-radius: 20px;
}
.rounded30{
    border-radius: 30px;
}

.shadow-home-banner{
    filter: drop-shadow(3px 3px 3px #919191);
}

.btn-zk-outline-white{
    border: 1px solid #fff !important;
    color: #fff;
    background-color: #ffffff00;
    border-radius: 30px 0px 30px 30px;
    font-weight: 500;
    font-family: montserrat !important;
}

.btn-zk-outline-primary{
    border: 1px solid var(--primary-color-light) !important;
    color: var(--primary-color-light);
    background-color: #ffffff00;
    border-radius: 30px 0px 30px 30px;
    font-weight: 500;
    font-family: montserrat !important;
}

.btn-zk-outline-white-hover{
    border: 1px solid #fff !important;
    color: #fff;
    background-color: #ffffff00;
    border-radius: 30px 0px 30px 30px;
    font-weight: 500;
    font-family: montserrat !important;
}

.btn-zk-outline-white-fill-hover{
    border: 1px solid #fff !important;
    color: #000;
    background-color: #fff;
    border-radius: 30px 0px 30px 30px;
    font-weight: 500;
    font-family: montserrat !important;

}
.btn-zk-outline-primary-fill-hover{
    border: 1px solid var(--primary-color-light) !important;
    color: #fff;
    background-color: var(--primary-color-light) !important;
    border-radius: 30px 0px 30px 30px;
    font-weight: 500;
    font-family: montserrat !important;
}
.btn-zk-outline-white-fill:active{
    color: #212529 !important;
    background-color: #fff !important;
    
}

.btn-zk-outline-primary:hover{
    border: 1px solid var(--primary-color-light) !important;
    color: #fff;
    background-color: var(--primary-color-light);
    border-radius: 30px 0px 30px 30px;
    font-weight: 500;
}

.btn-zk-outline-white:hover{
    border: 1px solid #fff !important;
    color: #fff;
    background-color: #ffffff3d;
    border-radius: 30px 0px 30px 30px;
    font-weight: 500;
    font-family: montserrat !important;
}

.btn-zk-outline-primary:active{
    color: #212529 !important;
    background-color: var(--primary-color-light) !important;
    border-color: var(--primary-color-light) !important;
}

.btn-zk-outline-white:active{
    color: #212529 !important;
    background-color: #fff !important;
    
}

.btn-zk-outline-white-hover:hover{
    border: 1px solid #fff !important;
    color: black;
    background-color: #ffffff;
    border-radius: 30px 0px 30px 30px;
    font-weight: 500;
    font-family: montserrat !important;
}

.btn-zk-outline-white-fill-hover:hover{
    border: 1px solid #fff !important;
    color: var(--primary-color-light);
    background-color: #ffffff;
    border-radius: 30px 0px 30px 30px;
    font-weight: 500;
    font-family: montserrat !important;
}

.btn-zk-outline-white-fill{
    border: 1px solid #fff !important;
    color: #000;
    background-color: #fff;
    border-radius: 30px 0px 30px 30px;
    font-weight: 500;
    font-family: montserrat !important;
    transition: all 0.3s ease;
}

.btn-zk-outline-white-rounded{
    border: 1px solid #fff !important;
    color: #000;
    background-color: #fff;
    border-radius: 30px 30px 30px 30px;
    font-weight: 500;
    font-family: montserrat !important;
    transition: all 0.3s ease;
    
}
.accordion-button{
    padding: 10px 20px !important;
}
.btn-zk-outline-white-rounded:hover{
    border: 1px solid #fff !important;
    color: #000;
    background-color: #f3f3f3;
    border-radius: 30px 30px 30px 30px;
    font-weight: 500;
    font-family: montserrat !important;
    transition: all 0.3s ease;
}

.btn-zk-outline-white-fill:hover{
    border: 1px solid #fff !important;
    color: #000;
    background-color: #ffffff;
    border-radius: 30px 30px 30px 0px;
    /* font-weight: 500; */
    font-family: montserrat !important;
    transition: all 0.3s ease;
}

.bg-zk-gradient{
    /* gradient left to right */
    background: var(--secondary-color);
    border-radius: 15vw 0 15vw 15vw
}

.home-getting-started{
    background-color: #ffffff00;
    color: #000 !important;
    border: 2px solid #fff;
}

.all-category-form{
    background-color: #ffffff00;
    color: #fff !important;
    border: 4px solid var(--secondary-color);
}

.home-getting-started:focus{
    background-color: #ffffff15;
    color: #fff !important;
    border: 2px solid #fff;
    box-shadow: none !important;
}

.all-category-form:focus{
    background-color: #ffffff15;
    color: #fff !important;
    border: 4px solid var(--secondary-color);
    box-shadow: none !important;
}

.input-white input::placeholder{
    color: #fff !important;
}
.home-getting-started-btn{
    background-color: var(--primary-color-light);
    color: #fff !important;
}

.home-getting-started-btn:focus{
    background-color: var(--primary-color-light);
    color: #fff !important;
    box-shadow: none !important;
}

.all-category-started-btn{
    background-color: var(--secondary-color);
    color: #fff !important;
}

.all-category-started-btn:focus{
    background-color: var(--secondary-color);
    color: #fff !important;
    box-shadow: none !important;
}

.accordion-button:focus{
    box-shadow: 0;
}
.accordion-button:not(.collapsed){
    background-color: #ededed !important;
}
.accordion-button:focus{
    box-shadow: none !important;
}

.btn-zk-white{
    background-color: #fff;
    color: #000 !important;
    border-radius: 30px 0 30px 30px;
    padding: 6px 45px;
    font-size: 17px;
    transition: all 0.3s ease;
}

.btn-zk-white-rounded{
    background-color: #fff;
    color: #000 !important;
    border-radius: 30px 30px 30px 30px;
    padding: 6px 45px;
    font-size: 17px;
    transition: all 0.3s ease;
}

.btn-zk-white-rounded:hover{
    background-color: #fff;
    color: #000 !important;
    border-radius: 30px 30px 30px 30px;
    padding: 6px 45px;
    font-size: 17px;
    transition: all 0.3s ease;
}


.btn-zk-white-rounded:active{
    background-color: #fff !important;
    color: #000 !important;
    border-radius: 30px 30px 30px 30px;
    padding: 6px 45px;
    font-size: 17px;
    transition: all 0.3s ease;
}

.btn-zk-white:hover{
    background-color: #fff;
    color: #000 !important;
    border-radius: 30px 30px 30px 0px;
    /* animate */
    transition: all 0.3s ease;
}
.btn-zk-white:active{
    background-color: #fff !important;
    color: #000 !important;
    border-radius: 30px 30px 30px 0px !important;
    /* animate */
    transition: all 0.3s ease;
    transition: all 0.3s ease;
    border: 0;
}

.bg-how-it-works{
    background: url('../images/how-it-work/bg_how.webp');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 150px 0 150px 150px;
}
.bg-faq{
    background: url('../images/faqs/faq-bg.webp');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 150px 0 150px 150px;
}
.form-control:focus{
    box-shadow: none !important;
    /* border: 1px solid var(--primary-color); */
}
.how-it-work-step{
    width: 70px;
    /* height: 100px; */
}
.home-getting-started-btn:hover{
    background-color: var(--primary-color);
    color: #fff !important;
    
}
.home-getting-started-btn:active{
    background-color: var(--primary-color) !important;
    color: #fff !important;
    
}

.pagination {
    margin-top: 45px;
    /* display: flex; */
    /* list-style: none; */
    text-decoration: none;
    /* outline: none; */
    
    }
    
    .pagination>.active>a {
    background-color: var(--secondary-color);
    color: #fff;
    text-decoration: none;

    }
    
    .pagination>li>a {
    border: 1px solid var(--secondary-color);
    padding: 5px 10px;
    /* outline: none; */
    cursor: pointer;
    text-decoration: none;

    }
    
    .pagination>li>a, .pagination>li>span {
    color: var(--secondary-color);
    background-color: rgb(255, 255, 255);
    }


.all-category-started-btn:hover{
    background-color: var(--secondary-color);
    color: #fff !important;
    
}
.all-category-started-btn:active{
    background-color: var(--secondary-color) !important;
    color: #fff !important;
    
}

.btn-zk-primary{
    background-color: var(--primary-color-light);
    color: #fff !important;
    padding: 4px 20px;
    font-size: 18px;
    transition: all 0.3s ease;
}

.btn-zk-primary:hover{
    background-color: var(--primary-color);
    color: #fff !important;
    /* animate */
    transition: all 0.3s ease;
    
}
.btn-zk-primary:active{
    background-color: var(--primary-color) !important;
    color: #fff !important;
    transition: all 0.3s ease;   
}

.btn-zk-secondary{
    background-color: var(--secondary-color);
    color: #fff !important;
    padding: 4px 20px;
    font-size: 18px;
    transition: all 0.3s ease;
}

.btn-zk-secondary:hover{
    background-color: var(--secondary-color);
    color: #fff !important;
    /* animate */
    transition: all 0.3s ease;
    
}
.btn-zk-secondary:active{
    background-color: var(--secondary-color) !important;
    color: #fff !important;
    transition: all 0.3s ease;   
}

.btn-zk-primary-light{
    background-color: var(--primary-color-light);
    color: #fff !important;
    border-radius: 30px 0 30px 30px;
    padding: 6px 45px;
    font-size: 18px;
    transition: all 0.3s ease;
}

.btn-zk-primary-light:hover{
    background-color: var(--primary-color);
    color: #fff !important;
    border-radius: 30px 30px 30px 0px;
    /* animate */
    transition: all 0.3s ease;
    
}
.btn-zk-primary-light:active{
    background-color: var(--primary-color) !important;
    color: #fff !important;
    border-radius: 30px 30px 30px 0px;
    /* animate */
    transition: all 0.3s ease;
    
}



.contact-page-bg{
    background: url('../images/contact/contact_bg.webp');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    /* border-radius: 150px 0 150px 150px; */
}

.lsp-2{
    letter-spacing: 2px;
}

.home-about-bg{
    background: url('../images/home/about-bg.webp');
    background-position: center;
    background-position: center;
}

.home-category-section{
    background-color: var(--secondary-color);
    border: 2px solid var(--secondary-color);
    padding: 10px 15px;
    border-radius: 30px;
    text-decoration: none;
    font-size: 18px;
    color: #fff;
    font-weight: 500;
    text-align: center;

}
.home-category-section:hover{
    background-color: #fff;
    color: var(--secondary-color);

}
.fw-600{
    font-weight: 600;
}
.fw-500{
    font-weight: 500;
}
#section1-home .margin70{
    margin-top: 70px;
}
.nav-tabs{
    border-bottom: 0;
    text-align:center;
}

.nav-tabs > .nav-link {
    float:none;
    display:inline-block;
}


.nav-tabs .nav-link{
    color: #000;
    font-weight: 500;
    border: 0;
    border-bottom: 0;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: var(--secondary-color);
    color: #fff;
}

.nav-tabs .nav-link.active{
    color: var(--primary-color-light);
    border-radius: 0%;
    border:4px solid var(--secondary-color);
    font-weight: 700;
    /* border-bottom: 0; */
}

.test-page-sidebar{
    position: sticky;
    bottom: 0;
    top: 10px;

}

.tab-pane-home{
    /* border: 1px solid var(--secondary-color); */
    border-top: 0;
}

.home-request-section{
    background: url('../images/home/home-request-section.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

}

.splash-screen {
    position: absolute;
    z-index: 1000;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: Helvetica, 'sans-serif';
    background-color: #f2f3f8;
    color: #5E6278;
    line-height: 1;
    font-size: 14px;
    font-weight: 400;
  }
  
.splash-screen span {
color: #5E6278;
transition: none !important;
text-size-adjust: 100%;
-webkit-font-smoothing: antialiased;
}

.splash-screen img {
margin-left: calc(100vw - 100%);
margin-bottom: 30px;
height: 30px !important;
}

/* Style the overlay */
.overlay {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.85);
    background-color: var(--secondary-color);
    background: url('../../assets/images/search_bg.webp');
    background-repeat: no-repeat;
    transition: 0.5s;
    overflow-x: hidden;
  }
  
  #search-overlay .overlay-content {
    position: relative;
    top: 25%;
    width: 100%;
    text-align: center;
    margin-top: 30px;
  }
  
  /* Style the open button */
  #search-overlay #search-overlay .open-button {
    margin-top: 30px;
    padding: 10px 30px;
    background: #2196f3;
    color: #fff;
    cursor: pointer;
    border: none;
    border-radius: 10px;
  }
  
  #search-overlay .open-button:hover {
    background: #0b7dda;
  }
  
  /* Style the "Close" button */
  #search-overlay .close-button {
    position: absolute;
    top: 20px;
    right: 45px;
    font-size: 60px;
    border: none;
    background: transparent;
    cursor: pointer;
    color: #fff;
  }
  
  /* Style search elements */
  #search-overlay .search-input {
    width: 400px;
    padding: 12px 15px;
    border: none;
    outline: none;
    border-radius: 30px 0px 0px 30px;
  }
  
  #search-overlay .search-button {
    padding: 12px 15px;
    border: none;
    cursor: pointer;
    background: #ffc107;
    border-radius: 0px 30px 30px 0px;
    transition: 0.3s;
  }
  
  #search-overlay .search-button:hover {
    background: #ff9800;
  }
  
  #search-overlay .search-text {
    color: rgba(255, 255, 255, 0.7);
    font-style: italic;
  }


.mx-md-start-sm-end{
    margin-right: auto!important;
    margin-left: auto!important;
}

.bg-zk-light{
    background-color: var(--light-gray-2) !important;
}

.text-page-middle-bg{
    background-color: var(--light-gray);
    border-radius: 20px;
    box-shadow: 0 .2rem 0.1rem rgba(49, 49, 49, 0.15)!important;
}
.text-page-middle-item{
    background-color: var(--secondary-color);
    border-radius: 10px;
}

.all-categorie-list ::marker {
    color: var(--primary-color-light);
    font-size: 30px;
}

.featrured-category-item{
    background: url('../images/all-categories/category-item-bg.webp');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 40px 0;
    box-shadow: 0 .2rem 0.1rem rgba(49, 49, 49, 0.15)!important;
}

.btn-zk-primary-cart{
    background-color: var(--primary-color-light);
    color: #fff;
    border: 0;
    font-weight: 600;
    font-family: var(--fonts) !important;
}

.btn-zk-primary-cart:hover{
    background-color: var(--primary-color);
    color: #fff;
}
.btn-zk-primary-cart:active{
    background-color: var(--primary-color) !important;
    color: #fff !important;

}

.border-end-footer{
    border-right: 1px solid var(--light-gray-2);
}

.all-categories-steps-bg{
    background: url('../images/all-categories/steps-bg.webp');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.rounded-20-0{
    border-radius: 20px 0;
}
.category-test-item{
    background-color: var(--light-gray-2);
    /* border-radius:0 0 50px 0; */
    box-shadow: 0 .2rem 0.5rem rgba(0,0,0,.15)!important;
    border-top: 5px solid var(--secondary-color);
}
.w-50px{
    width: 50px;
}
.w-35 {
    width: 35%!important;
}
.w-40 {
    width: 40%!important;
}

.w-60 {
    width: 60%!important;
}


.w-65 {
    width: 65%!important;
}

  /* ==================================================

                    Media Queries

    ================================================== */

@media screen and (max-width: 1150px){
    #website-nav .container-zk{
        padding-left: 0px;
        padding-right: 0px;
    }
    #website-nav #home-header .nav-link{
        margin-right: 6px;
    }
    #website-nav .nav-link{
        margin-right: 1px;
    }
}

@media screen and (max-width: 991px) {

    #home-header .logo-img{
        height: 50px !important;
    }
    .page-banner-content{
        top: 8vw;
    }
    #website-nav{
        padding-top: 0;
    }
    
    #website-nav .container-zk{
        padding-left: 30px;
        padding-right: 30px;
       
    }
    #myVideo-mb{
        height: 80vh;
    }
    .home-banner-common{
        height: 80vh !important;
    }
    #header-process-mb .accordion-button{
        background-color: #ffffff !important;
        width: max-content ;
        color: #000;
        border-radius: 30px;
        margin: 10px 0;
        padding: 6px 10px;
    }
    #header-process-mb .accordion-button:focus{
        border: 0;
        box-shadow: none;
    }
    /* .home-banner-common{
        background: url("../../assets/images/home/banner_mb.webp");
        background-size: cover;
        background-position: center;
        height: 70vh;

    } */
    #website-responsive .fs-zk-56{
        font-size:38px;
    }

    #website-responsive .fs-zk-52{
        font-size:34px;
    }


    #website-responsive .fs-zk-48{
        font-size:32px;
    }

    #website-responsive .fs-zk-40{
        font-size:28px;
    }

    #website-responsive .fs-zk-35{
        font-size:26px;
    }
    #website-responsive .fs-zk-30{
        font-size:24px;
    }
    #website-responsive .fs-zk-28{
        font-size:22px;
    }

    #website-responsive .fs-zk-26{
        font-size:21px;
    }

    #website-responsive .fs-zk-24{
        font-size:20px;
    }

    #website-responsive .fs-zk-22{
        font-size:19px;
    }

    #website-responsive .fs-zk-20{
        font-size:18px;
    }

    .footer-bg .fs-zk-20{
        font-size:16px !important;
    }

    #website-responsive .fs-zk-18{
        font-size:17px;
    }
    #website-responsive .fs-zk-17{
        font-size:16px;
    }

    #website-responsive .fs-zk-16{
        font-size:16px;
    }
    /* .home-banner-content .fs-zk-48{

    } */

    .btn-zk-outline-white-fill{
        font-size: 14px !important;
    }
    .all-categories-faq .container-zk{
        padding-right: 10px !important;
        padding-left: 10px !important;
    }
    
    .faq .container-zk{
        padding-right: 10px;
        padding-left: 10px;
    }
    .container-zk{
        max-width: 100%;
        padding-right: 30px;
        padding-left: 30px;
        margin-right: auto;
        margin-left: auto;
    }
    .container-zk2{
        max-width: 100%;
        padding-right: 30px;
        padding-left: 30px;
        margin-right: auto;
        margin-left: auto;
    }
    .lsp-2{
        letter-spacing: unset;
    }

    /* .nav-link{
        border-bottom: 1px solid var(--primary-color-light);
    } */

    .btn-zk-outline-white-fill-hover{
        background-color:none;
        color: #212529;
        padding: 10px 0 !important;
        font-weight: 400;
        font-size: 16px;
    }
    .btn-zk-outline-primary-fill-hover{
        border-bottom: 1px solid var(--primary-color-light);
        border: 0 !important;
        background-color:#fff !important;
        color: #212529;
        padding: 10px 0 !important;
        font-weight: 400;
        font-size: 16px;
        text-align: left;
        text-transform: uppercase;
    }
    .btn-zk-outline-white-fill-hover:hover{
        color: #212529;
    }
    
    .btn-search-nav{
        background-color: var(--primary-color-light);
        border-radius: 0 30px 30px 0;
    }
    .btn-search-nav:hover{
        background-color: var(--primary-color-light);
    }
    .btn-search-nav:active{
        background-color: var(--primary-color-light) !important;
    }
    .category-test-item{
        border-radius: 0 0 10px 10px;
    }
}

@media screen and (max-width: 767px) {
    #search-overlay .overlay-content{
        top:15%;
    }
    
    .page-banner-content{
        top: 23vw;
        text-align: center;
    }
    .page-banner-title{
        font-size: 7vw;
        font-weight: 700;
        line-height: 1.1;
    }
    .home-page-banner-content{
        top: 15vw;
        width: 57%;
    }
    .home-page-banner-title{
        font-size: 5vw;
        font-weight: 700;
        line-height: 1.3;
    }
    .home-page-banner-desc{
        font-size: 3.8vw;
        line-height: 1.1;
    }
    .btn-zk-primary-light-home{
        padding: 3px 15px;
        font-size: 14px;
    }
    .bg-how-it-works, .bg-faq{
        border-radius: 20px;
    }

    .mx-md-start-sm-end{
        text-align: center;
    }
    .how-it-work-step{
        width: 60px;
        /* height: 80px; */
    }
    #myVideo-mb{
        height: 70vh;
    }
    .home-banner-common{
        height: 70vh !important;
    }
    #home-header .logo-img{
        height: 50px !important;
    }
    
    .logo-img{
        height: 50px !important;
    }
    .home-banner-content{
        padding: 0 30px;
        text-align: center;
    }
    .home-banner-content .fs-zk-48{
        margin-top: 15px;
        font-size: 32px !important;
        font-weight: 800 !important;
        
    }
    .header-process .btn-zk-outline-white-fill {
        margin-top: 0  !important;
        font-size: 14px !important;
        /* padding: 10px !important; */
    }
    .btn-zk-outline-white-fill{
        font-size: 15px !important;
        font-weight: 600 !important;
        margin-top: 20px;
    }

    .btn-zk-primary-light{
        padding: 7px 30px;
        font-size: 16px;
        transition: all 0.3s ease;
    }

    .btn-zk-primary{
        padding: 7px 30px;
        font-size: 16px;
        transition: all 0.3s ease;
    }

    .btn-zk-white{
        padding: 7px 30px;
        font-size: 16px;
        transition: all 0.3s ease;
    }


}

@media screen and (max-width: 575px) {
    .home-banner-common{
        /* background-position: center;  */
        background-size: cover;
        background-repeat: no-repeat;
    }

    #website-responsive .fs-zk-56{
        font-size:30px;
    }

    #website-responsive .fs-zk-52{
        font-size:28px;
    }

    #website-responsive .fs-zk-48{
        font-size:26px;
    }

    #website-responsive .fs-zk-40{
        font-size:23px;
    }

    #website-responsive .fs-zk-35{
        font-size:21px;
    }
    #website-responsive .fs-zk-30{
        font-size:20px;
    }
    #website-responsive .fs-zk-28{
        font-size:19px;
    }

    #website-responsive .fs-zk-26{
        font-size:19px;
    }

    #website-responsive .fs-zk-24{
        font-size:18px;
    }

    #website-responsive .fs-zk-22{
        font-size:18px;
    }

    #website-responsive .fs-zk-20{
        font-size:17px;
    }

    .footer-bg .fs-zk-20{
        font-size:16px !important;
    }

    #website-responsive .fs-zk-18{
        font-size:17px;
    }
    #website-responsive .fs-zk-17{
        font-size:16px;
    }

    #website-responsive .fs-zk-16{
        font-size:16px;
    }

    .home-category-section{
        padding: 5px 10px;
    }
    #myVideo-mb{
        height: 60vh;
    }
    .home-banner-common{
        height: 60vh !important;
    }
    /* .home-banner-content .fs-zk-48{
        font-size: 24px !important;
    } */

    .home-banner-content{
        padding: 0 30px;
        text-align: center;
    }
    .home-banner-content .fs-zk-48{
        margin-top: 15px;
        font-size: 32px !important;
        font-weight: 800 !important;
    }
    .btn-zk-outline-white-fill{
        font-size: 15px !important;
        font-weight: 600 !important;
        margin-top: 20px;
    }
    .btn-zk-white{
        padding: 6px 20px;
        font-size: 15px;
        transition: all 0.3s ease;
    }
}


@media screen and (max-width: 480px) {
    #myVideo-mb{
        height: 60vh;
    }
    .home-banner-common{
        height: 60vh !important;
    }
    .home-banner-content{
        padding: 0 30px;
        text-align: center;
    }
    .home-banner-content .fs-zk-48{
        margin-top: 15px;
        font-size: 32px !important;
        font-weight: 800 !important;
    }
    .btn-zk-outline-white-fill{
        font-size: 15px !important;
        font-weight: 600 !important;
        margin-top: 20px;
    }

}

@media screen and (max-width: 400px) {
    #myVideo-mb{
        height: 75vh;
    }
    .home-banner-common{
        height: 75vh !important;
    }
    .home-banner-content{
        padding: 0 30px;
        text-align: center;
    }
    .home-banner-content .fs-zk-48{
        margin-top: 15px;
        font-size: 32px !important;
        font-weight: 800 !important;
    }
    .btn-zk-outline-white-fill{
        font-size: 15px !important;
        font-weight: 600 !important;
        margin-top: 20px;
    }

}


@media screen and (max-width: 320px) {
    #myVideo-mb{
        height: 82vh;
    }
    .home-banner-common{
        height: 82vh !important;
    }
}


@media screen and (max-width: 280px) {

}

@media screen and (max-width: 240px) {

}